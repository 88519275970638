const loadouts = [
	[
		[4, 5, 3, 6, 9, 1, 7, 2, 8],
		[8, 7, 9, 3, 5, 2, 4, 6, 1],
		[2, 1, 6, 8, 4, 7, 9, 3, 5],
		[3, 2, 1, 4, 6, 5, 8, 9, 7],
		[7, 6, 8, 2, 1, 9, 3, 5, 4],
		[5, 9, 4, 7, 8, 3, 6, 1, 2],
		[1, 3, 5, 9, 7, 8, 2, 4, 6],
		[6, 8, 2, 1, 3, 4, 5, 7, 9],
		[9, 4, 7, 5, 2, 6, 1, 8, 3],
	],
	[
		[1, 3, 5, 9, 7, 8, 2, 4, 6],
		[6, 8, 2, 1, 3, 4, 5, 7, 9],
		[9, 4, 7, 5, 2, 6, 1, 8, 3],
		[4, 5, 3, 6, 9, 1, 7, 2, 8],
		[8, 7, 9, 3, 5, 2, 4, 6, 1],
		[2, 1, 6, 8, 4, 7, 9, 3, 5],
		[3, 2, 1, 4, 6, 5, 8, 9, 7],
		[7, 6, 8, 2, 1, 9, 3, 5, 4],
		[5, 9, 4, 7, 8, 3, 6, 1, 2],
	],
	[
		[6, 9, 1, 7, 2, 8, 4, 5, 3],
		[3, 5, 2, 4, 6, 1, 8, 7, 9],
		[8, 4, 7, 9, 3, 5, 2, 1, 6],
		[4, 6, 5, 8, 9, 7, 3, 2, 1],
		[2, 1, 9, 3, 5, 4, 7, 6, 8],
		[7, 8, 3, 6, 1, 2, 5, 9, 4],
		[9, 7, 8, 2, 4, 6, 1, 3, 5],
		[1, 3, 4, 5, 7, 9, 6, 8, 2],
		[5, 2, 6, 1, 8, 3, 9, 4, 7],
	],
	[
		[3, 2, 1, 4, 6, 5, 8, 9, 7],
		[7, 6, 8, 2, 1, 9, 3, 5, 4],
		[5, 9, 4, 7, 8, 3, 6, 1, 2],
		[4, 5, 3, 6, 9, 1, 7, 2, 8],
		[8, 7, 9, 3, 5, 2, 4, 6, 1],
		[2, 1, 6, 8, 4, 7, 9, 3, 5],
		[1, 3, 5, 9, 7, 8, 2, 4, 6],
		[6, 8, 2, 1, 3, 4, 5, 7, 9],
		[9, 4, 7, 5, 2, 6, 1, 8, 3],
	],
];

export default loadouts;
